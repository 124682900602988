import { useEffect, useState } from 'react';
import Head from 'next/head';
import Page from '../app/hoc/defaultPage';
import dynamic from 'next/dynamic';
import CircularProgress from '../app/components/CircularProgress';
import { getSettings } from '../utils/session';

const Component = dynamic(() => import('../routes/extraPages/404'), {
  loading: () => <CircularProgress />,
});

export default Page(() => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    if (typeof window != 'undefined') {
      setSettings(getSettings());
    }
  }, []);

  return (
    <>
      <Head>
        <title> {settings['app.name']} | Not Found</title>
      </Head>
      <Component />
    </>
  );
});
